import React, { useState } from 'react';
import { FormikProps, useFormik } from 'formik';
import { Container } from '../../../../primitives/styles/WizardSteps/WizardStepContainerStyle';
import { OnboardingContainer, SubTitle } from '../../../../primitives/styles/Onboarding/Onboarding';
import BreadcrumbsHeader from '../../../../components/Breadcrumbs/BreadcrubmbsHeader';
import TITLES from '../../../../constants/Titles';
import fontSize from '../../../../constants/FontSize';
import Title from '../../../../components/Title/Title';
import Phrase from '../../../../components/Phrase/Phrase';
import LocationTextField from '../../Location/CreateLocation/components/CreateLocationTextField';
import { useSelector } from 'react-redux';
import { SelectTranslations } from '../../../../store/translationSlice';
import FloatingButton from '../../../../components/Common/FloatingButton';
import Note from '../../Location/SetLocation/components/Note';

type CreateOrganizationProps = {
  theme: any;
  onNext: (values: any) => Promise<void>;
}

enum CreateOrganizationSteps {
  NAME = 1
}

export interface CreateOrganizationForm {
  name?: string
}

const CreateOrganization: React.FC<CreateOrganizationProps> = ({
  theme,
  onNext,
}) => {
  const translations = useSelector(SelectTranslations);
  const [currentStep, setCurrentStep] = useState<CreateOrganizationSteps>(CreateOrganizationSteps.NAME);

  const nameLabelText: string = translations['ff994431-aafd-4b69-a39c-59656f75c330'] || 'Organization Name';
  const requiredText  = translations['a615fa81-f3f3-41d4-be4d-1684cff14737'] || 'This field is required';

  const titlesStyle = {
    whiteSpace: 'pre-line',
    textAlign: 'left',
    textShadow: 'none',
    width: '100%'
  };

  const subTitleStyle: Object = {
    textAlign: 'left',
    width: '100%',
    height: '15%'
  };

  const validate = (values: CreateOrganizationForm) => {
    const errors: CreateOrganizationForm = {};

    if (!values.name) {
      errors.name = 'required'
    }

    return errors;
  };

  const handleSubmit = async () => {
    try {
      await onNext({...formik.values });
    } catch (err) {
      console.log({ err });
    }
  }

  const formik: FormikProps<CreateOrganizationForm> = useFormik<CreateOrganizationForm>({
    initialValues: {
      name: '',
    },
    validate,
    onSubmit: handleSubmit
  })

  return (
    <Container>
      <BreadcrumbsHeader
        currentStep={currentStep}
        totalSteps={1}
        flow="createOrg"
        shouldRenderClose={false}
        shouldRenderBack={false}
      />
      <OnboardingContainer>
        <Title
          type="flow"
          text="ff994431-aafd-4b69-a39c-59656f75c332"
          defaultText="Set an organization name"
          size={fontSize.MID_LARGE}
          style={titlesStyle}
        />
        <SubTitle style={subTitleStyle}>
          <Phrase
            defaultText="Create a organization name"
            translationKey="ff994431-aafd-4b69-a39c-59656f75c333"
          />
        </SubTitle>
        <LocationTextField
          id={'name'}
          name={'name'}
          onBlur={formik?.handleBlur}
          label={nameLabelText}
          value={formik?.values.name}
          onChange={formik?.handleChange}
          style={{ width: '100%' }}
          error={!!formik?.errors.name && !!formik?.touched.name}
          errorText={requiredText}
        />
        {!formik?.values.name && (
          <Note
            defaultText={'For example:\n`Cafe Noir'}
            translationKey={'ff994431-aafd-4b69-a39c-59656f75c334'}
            style={{ width: '100%', marginTop: '20%', justifyContent: 'flex-start' }}
          />
        )}
        <FloatingButton
          onClick={formik.handleSubmit}
          isDisabled={false}
          position={'fixed'}
          variant={'contained'}
          buttonText={'0d24354a-d507-44d3-aaf3-a4c017b62907'}
          buttonDefaultText={"I'm Done"}
        />
      </OnboardingContainer>
    </Container>
  );
}

export default CreateOrganization;